import "react-app-polyfill/stable";
import "core-js";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
window.addEventListener("DOMContentLoaded", function (e) {
  createRoot(document.getElementById("root")).render(
    <Provider store={store}>
      <GoogleReCaptchaProvider
        reCaptchaKey="6Le8vwsqAAAAAE2sFIx55U1htC97gsB_p4yU-3cn"
        scriptProps={{
          async: false,
          defer: false,
          appendTo: "head",
          nonce: undefined,
        }}
      >
        <App />
      </GoogleReCaptchaProvider>
    </Provider>
  );
});
